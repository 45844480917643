/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import { Avatar, List, Popconfirm } from 'antd';
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined
} from '@ant-design/icons';
// import notificationData from 'assets/data/notification.data.json'

import notificationService from 'service/notification';
import { Link, NavLink, useNavigate } from 'react-router-dom';

import CategoryProduct2 from '../filters/CategoryProduct2';
// import CategoryProduct3 from '../ecommerce/Filter/CategoryProduct3'
import Search from '../common/Search';

// Icons
import logoIcon from 'assets/imgs/theme/logofinal.svg';
import logoIcons from 'assets/imgs/theme/icons/zap.svg';
import MenuIcons from 'assets/imgs/theme/icons/Menuicon.svg';
// import compareIcon from 'assets/imgs/theme/icons/icon-compare.svg'
import heartIcon from 'assets/imgs/theme/icons/icon-heart.svg';
import cartIcon from 'assets/imgs/theme/icons/icon-cart.svg';
import userIcon from 'assets/imgs/theme/icons/icon-user.svg';
import icon1 from 'assets/imgs/theme/icons/icon-1.svg';

import icon2 from 'assets/imgs/theme/icons/icon-2.svg';
import icon3 from 'assets/imgs/theme/icons/icon-3.svg';
import icon4 from 'assets/imgs/theme/icons/icon-4.svg';
import { useDispatch, useSelector } from 'react-redux';
import { setLogout } from 'redux/slices/authSlice';
import { getCategories } from 'redux/slices/categorySlice';
import { getCartSlice } from 'redux/slices/cartSlice';
import { getWishlistSlice } from 'redux/slices/wishlistSlice';
import wishlists from 'assets/imgs/theme/icons/Wish.svg';
import carts from 'assets/imgs/theme/icons/CartIcon.svg';
import ProfileIcon from 'assets/imgs/theme/icons/profile-icon.svg';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  getProductsTemplateBySearch,
  setFilters
} from 'redux/slices/productTemplateSlice';
import SearchSuggestion from 'components/common/SearchSuggestion';
import categoryService from 'service/category';
import moment from 'moment';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Button, Modal, Space } from 'antd';
const { confirm } = Modal;

// import hotIcon from 'assets/imgs/theme/icons/icon-hot.svg'
// import headphoneIcon from 'assets/imgs/theme/icons/icon-headphone.svg'

// TODO Replace <Link> to <NavLink> if that is real navigation Link

const getIcon = (icon) => {
  switch (icon) {
    case 'mail':
      return <MailOutlined />;
    case 'alert':
      return <WarningOutlined />;
    case 'check':
      return <CheckCircleOutlined />;
    default:
      return <MailOutlined />;
  }
};
const getNotificationBody = (list) => {
  //console.log(list, 'lokkk');

  return list.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable">
          <div className="pr-3">
            {item.img ? (
              <Avatar src={`/img/avatars/${item.img}`} />
            ) : (
              <Avatar
                className={`ant-avatar-${item.type}`}
                icon={getIcon(item.icon)}
              />
            )}
          </div>
          <div className="mr-3">
            <span className="font-weight-bold text-dark">{item.fcm.name} </span>
            <span className="text-gray-light">{item.fcm.description}</span>
          </div>
          <small className="ml-auto">
            {moment(item.createdAt).format('DD-MM-YYYY hh:mm a')}
          </small>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-nootifications ">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  );
};
const Header = ({ toggleClick, showCategories = true }) => {
  // const totalCartItems = 3
  // const totalCompareItems = 2
  // const totalWishlistItems = 2
  const [isToggled, setToggled] = useState(false);
  const [scroll, setScroll] = useState(0);
  const [initialCategories, setInitialCategories] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSuggestionShown, setIsSuggestionShown] = useState(false);
  const [visible, setVisible] = useState(false);
  // const [data, setData] = useState(notificationData)
  const [list, setList] = useState([]);
  const [page, setPage] = useState(1);

  

  useEffect(() => {
    if (page > 1) {
      dispatch(
        getProductsTemplateBySearch(
          `page=${page}&limit=20`,
          `&search=${searchTerm}`
        )
      );
    }
  }, [page]);

  const { authorized, user } = useSelector((state) => state.auth);

  const { totalCartItems, isFetched: isCartFetched } = useSelector(
    (state) => state.cart
  );
  const { totalWishlistItems, isFetched: isWishlistCartFetched } = useSelector(
    (state) => state.wishlist
  );

  const { filters, searchSuggestions } = useSelector(
    (state) => state.productTemplate
  );

  const { categoriesHeader: categories } =
    useSelector((state) => state.category);


  const navigate = useNavigate();

  const dispatch = useDispatch();

  const setIsScrollingHandler = () => {
    const scrollCheck = window.scrollY >= 300 && window.innerHeight >= 600;
    setScroll((prevIsScroll) => {
      if (prevIsScroll !== scrollCheck) return scrollCheck;
      return prevIsScroll;
    });
  };

  useEffect(() => {
    dispatch(
      getCategories(
        'orderByHomePriority=true&excludePriorityZero=true',
        'header'
      )
    );
  }, [])

  useEffect(() => {
    if (showCategories)
      window.addEventListener('scroll', setIsScrollingHandler);

    return () => window.removeEventListener('scroll', setIsScrollingHandler);
  }, [showCategories]);

  const handleToggle = () => setToggled(!isToggled);

  useEffect(() => {
    if (authorized) {
      !isCartFetched && dispatch(getCartSlice());
      !isWishlistCartFetched && dispatch(getWishlistSlice());
    }
  }, [authorized, isCartFetched, isWishlistCartFetched]);

  // const getCategories = async () => {
  //   const data = await categoryService.getCategories(
  //     ('orderByHomePriority=true', 'excludePriorityZero=true')
  //   );

  //   if (data) {
  //     setCategories(data);
  //   }
  // };

  // useEffect(() => {
  //   getCategories();
  // }, []);

  const createCategoryList = (categories, parentId = null) => {
    const categoryList = [];
    let category;
    if (parentId == null) {
      category = categories.filter((cat) => !cat?.parentId);
    } else {
      category = categories.filter((cat) => cat?.parentId === parentId);
    }
    // eslint-disable-next-line prefer-const
    for (let cate of category) {
      categoryList.push({
        id: cate.id,
        title: cate.name,
        value: cate.id,
        key: cate.id,
        children: createCategoryList(categories, cate.id)
      });
    }

    return categoryList;
  };

  useEffect(() => {
    if (categories) {
      const tree = createCategoryList(categories);
      

      const sortByInnerChild = tree.map((first) => {
        if (first.children?.length === 0) {
          return { ...first, nestedChildrenLvl: 0 };
        } else if (first.children?.length > 0) {
          let findInner;
          first.children.map((second) => {
            if (second.children?.length === 0) {
              findInner = 1;
            } else if (second.children?.length > 0) {
              findInner = 2;
            }
          });
          return { ...first, nestedChildrenLvl: findInner };
        }
      });

      setInitialCategories(sortByInnerChild.slice(0, 7));
    }
  }, [categories]);

  const addTofilter = (catId, catName, mainCategory = undefined) => {
    const filters = [
      { filterName: 'categoryId', value: catId },
      { filterName: '', value: catId },
      { filterName: 'selected', value: `category-${catName}` },
    ]

    if (mainCategory) {
      filters.push({ filterName: 'mainCategory', value: mainCategory })
    }
    dispatch(
      setFilters(filters)
      // setFilters([
      //   { filterName: 'categoryId', value: catId },
      //   { filterName: '', value: catId },
      //   { filterName: 'selected', value: `category-${catName}` },
      //   { filterName: 'mainCategory', value: mainCategory }
      // ])
    );
  };

  const onSearchSubmit = (text) => {
    // setSearchTerm(text);
    setIsSuggestionShown(false);

    dispatch(
      setFilters([
        { filterName: 'search', value: searchTerm },
        { filterName: 'selected', value: 'search' }
      ])
    );

    navigate(`/products?search=${searchTerm}&selected=search`);
  };

  const onSelect = (productTemplateSlug, productId, productType, category) => {
    //console.log("category 222", category)
    if (productType === 'Medicine') {
      navigate(`/${category?.replaceAll(/\s+/g, '-')}/${productTemplateSlug}/${productId}/medicine`);
    } else {
      navigate(`/${category?.replaceAll(/\s+/g, '-')}/${productTemplateSlug}/${productId}`);
    }
  };

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setIsSuggestionShown(true);

    dispatch(getProductsTemplateBySearch(`&search=${e.target.value}`));
  };

  const handleInput = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      onSearchSubmit();
    }
  };
  const formSubmit = (e) => {
    e.preventDefault();
    onSearchSubmit();
  };

  // const getNotifications = async () => {
  //   const data = await notificationService.getNotifications()
  //   if (data) {
  //     setList(data)
  //   }
  // }
  // useEffect(() => {
  //   getNotifications()
  // }, [])

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const showConfirm = () => {
    confirm({
      title: 'Do you want to logout?',
      icon: <ExclamationCircleFilled />,
      onOk() {
        dispatch(setLogout());
      },
      onCancel() { }
    });
  };

  // const notificationList = (
  //   <div className="nav-dropdown nav-notification">
  //     <div className="nav-notification-header d-flex justify-content-between align-items-center">
  //       <h5 className="mb-0">Notification</h5>
  //       <Button
  //       // className="text-primary"
  //       // type="text"
  //       // onClick={() => setData([])}
  //       // size="small"
  //       >
  //         Clear{' '}
  //       </Button>
  //     </div>
  //     {/* <hr></hr> */}
  //     <div className="nav-notification-body">{getNotificationBody(list)}</div>
  //     {list.length > 0 ? (
  //       <div className="nav-notification-footer">
  //         <a className="d-block" href="#/">
  //           View all
  //         </a>
  //       </div>
  //     ) : null}
  //   </div>
  // )


  //Observer for lazy loading of Images
  const config = {
    rootMargin: '100px 100px 0px 0px',
    threshold: 0,
  }

  useEffect(() => {
    let observer = new window.IntersectionObserver(function (entries, self) {
      //iterate over each entry
      entries.forEach((entry) => {
        //process whether image are intersecting
        if (entry.isIntersecting) {
          //custom function that copies the path to the img from data-src to src
          loadImages(entry.target)
          //image is now in place so stop watching
          self.unobserve(entry.target)
        }
      })
    }, config)

    const imgs = document.querySelectorAll('[data-src]')
    imgs.forEach(img => {
      observer.observe(img);
    })

    return () => {
      imgs.forEach(img => {
        observer.unobserve(img);
      })
    }
  }, [])

  function loadImages(image) {
    image.src = image.dataset.src;
  }


  return (
    <>
      <header
        className="header-area header-style-1 header-height-2"
        style={
          {
            // pointerEvents: window.location.pathname.includes(
            //   '/user-authdetails-update'
            // )
            //   ? 'none'
            //   : 'all'
          }
        }
      >
        {/* <div className="mobile-promotion"> */}
        {/* <span>
            Grand opening, <strong>up to 15%</strong> off all items. Only{' '}
            <strong>3 days</strong> left
          </span> */}
        {/* </div> */}

        {/* <li>
                      <Link to="/about">About Us</Link>
                    </li> */}
        {/* <li>
                      <Link to="/account#dashboard">My Account</Link>
                    </li>
                    <li>
                      <Link to="/wishlist">Wishlist</Link>
                    </li>
                    <li>
                      <Link to="/account#orders">Order Tracking</Link>
                    </li> */}

        {/* <ul>
                      <li>
                        Get Medicines up to 40% off
                      
                      </li>
                    </ul> */}

        {/* <div className="col-xl-3 col-lg-4">
                <div className="header-info header-info-right">
                  <ul>
                    <li>
                      Need help? Call Us:{' '}
                      <strong className="text-brand"> + 1800 900</strong>
                    </li>
                  </ul>
                </div>
              </div> */}

        <div className="header-middle header-middle-ptb-1 d-none d-lg-block">
          <div className="container">
            <div className="header-wrap">
              <div className="logo logo-width-1">
                <Link to="/">
                  <img
                    src={logoIcon}
                    alt="logo"
                  />
                </Link>
              </div>
              <div className="header-right">
                <div className="search-style-2">
                  <Search />
                </div>
                {/* <Dropdown
                  placement="bottomLeft"
                  overlay={notificationList}
                  onVisibleChange={handleVisibleChange}
                  visible={visible}
                  trigger={['click']}
                >
                  <Menu mode="horizontal">
                    <Menu.Item key="notification">
                      <Badge count={list.length}>
                        <BellOutlined
                          className="nav-icon mx-auto"
                          type="bell"
                        />
                      </Badge>
                    </Menu.Item>
                  </Menu>
                </Dropdown> */}
                <div className="header-action-right">
                  <div className="header-action-2">
                    {/* <div className="search-location">
                      <form action="#">
                        <select className="select-active">
                          <option>Your Location</option>
                          <option>Alabama</option>
                          <option>Alaska</option>
                          <option>Arizona</option>
                          <option>Delaware</option>
                          <option>Florida</option>
                          <option>Georgia</option>
                          <option>Hawaii</option>
                          <option>Indiana</option>
                          <option>Maryland</option>
                          <option>Nevada</option>
                          <option>New Jersey</option>
                          <option>New Mexico</option>
                          <option>New York</option>
                        </select>
                      </form>
                    </div> */}
                    {/* <div className="header-action-icon-2">
                      <Link to="/shop-compare">
                        <img
                          className="svgInject"
                          alt="Evara"
                          src={compareIcon}
                        />
                        <span className="pro-count blue">
                          {totalCompareItems}
                        </span>
                      </Link>
                      <Link to="/shop-compare">
                        <span className="lable ml-0">Compare</span>
                      </Link>
                    </div> */}

                    {/* <Dropdown
                      placement="bottomLeft"
                      overlay={notificationList}
                      onVisibleChange={handleVisibleChange}
                      visible={visible}
                      trigger={['click']}
                    >
                      <Menu mode="horizontal">
                        <Menu.Item key="notification">
                          <Badge count={list.length}>
                            <BellOutlined
                              className="nav-icon mx-auto"
                              type="bell"
                            />
                          </Badge>
                        </Menu.Item>
                      </Menu>
                    </Dropdown> */}
                    <div className="header-action-icon-2 gap-2">
                      <Link to="/wishlist">
                        <img
                          className="svgInject"
                          alt="Evara"
                          // src={heartIcon}
                          src={wishlists}
                        />
                        {totalWishlistItems > 0 && (
                          <span className="pro-count blue">
                            {totalWishlistItems}
                          </span>
                        )}
                        {/* <span className="pro-count blue">
                          {totalWishlistItems}
                        </span> */}
                      </Link>
                      <Link to="/wishlist">
                        <span className="lable">Wishlist</span>
                      </Link>
                    </div>

                    <div className="header-action-icon-2">
                      <Link to="/cart" className="mini-cart-icon">
                        <img
                          alt="Evara"
                          // src={cartIcon}
                          src={carts}
                          style={{
                            width: '33px',
                            // height: '40px',
                            marginBottom: '3px'
                          }}
                        />
                        {totalCartItems > 0 && (
                          <span
                            className="pro-count blue"
                            style={{ top: '-3px' }}
                          >
                            {totalCartItems}
                          </span>
                        )}
                      </Link>

                      <Link to="/cart">
                        <span className="lable">Cart</span>
                      </Link>
                    </div>

                    <div className="header-action-icon-2">
                      <Link to="/account#dashboard">
                        <img
                          className="svgInject"
                          alt="Zapkart"
                          // src={userIcon}
                          src={ProfileIcon}
                        />
                      </Link>
                      {authorized ? (
                        <>
                          <Link to="/account#dashboard">
                            <span className="lable ml-0">
                              {user?.firstName
                                ? `${user?.firstName}`
                                : 'Account'}
                            </span>
                          </Link>
                          <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                            <ul>
                              <li>
                                <Link to="/account#dashboard">
                                  <i className="fi fi-rs-user mr-10"></i>
                                  My Account
                                </Link>
                              </li>
                              {/* <li>
                                <Link to="/account">
                                  <i className="fi fi-rs-location-alt mr-10"></i>
                                  Order Tracking
                                </Link>
                              </li> */}
                              {/* <li>
                                <Link to="/account">
                                  <i className="fi fi-rs-label mr-10"></i>
                                  My Voucher
                                </Link>
                              </li> */}
                              {/* <li>
                                <Link to="/wishlist">
                                  <i className="fi fi-rs-heart mr-10"></i>
                                  My Wishlist
                                </Link>
                              </li> */}
                              {/* <li>
                                <Link to="/account">
                                  <i className="fi fi-rs-settings-sliders mr-10"></i>
                                  Setting
                                </Link>
                              </li> */}
                              <li onClick={showConfirm}>
                                {/* <Popconfirm
                                  title="Do you want to logout?"
                                  onConfirm={() => {
                                    dispatch(setLogout());
                                  }}
                                  className="logout-pop"
                                  okText="Yes"
                                  cancelText="No"
                                ></Popconfirm> */}
                                <Link to="">
                                  <i className="fi fi-rs-sign-out mr-10"></i>
                                  Sign out
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </>
                      ) : (
                        <Link to="/login">
                          <span className="lable"> Login | Register</span>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {
          <div
            className={
              scroll
                ? 'header-bottom header-bottom-bg-color sticky-bar stick'
                : 'header-bottom header-bottom-bg-color sticky-bar'
            }
          >
            <div className="container">
              <div className="header-wrap header-space-between position-relative">
                <div className="logo logo-width-1  d-lg-none">
                  <Link to="/">
                    <img
                      src={""}
                      data-src={logoIcon}
                      alt="logo"
                    />
                  </Link>
                </div>
                {showCategories && (
                  <div className="header-nav d-none d-lg-flex">
                    <OutsideClickHandler
                      onOutsideClick={() => setToggled(false)}
                    >
                      <div className="main-categori-wrap d-none d-lg-block">
                        <button
                          className="categories-button-active"
                          onClick={handleToggle}
                        >
                          <i className="fi-rs-apps fs-6"></i>
                          <p>Browse All Categories</p>
                          <i className="fi-rs-angle-down"></i>
                        </button>

                        <div
                          className={
                            isToggled
                              ? 'categories-dropdown-wrap categories-dropdown-active-large font-heading open'
                              : 'categories-dropdown-wrap categories-dropdown-active-large font-heading'
                          }
                        >
                          <CategoryProduct2 categories={categories} />
                          {/* <CategoryProduct3 /> */}

                          {/* <div
                      className="more_slide_open"
                      style={{ display: 'none' }}
                    >
                      <div className="d-flex categori-dropdown-inner">
                        <ul>
                          <li>
                            <Link to="/products">
                              <img src={icon1} alt="" />
                              Milks and Dairies
                            </Link>
                          </li>
                          <li>
                            <Link to="/products">
                              <img src={icon2} alt="" />
                              Clothing & beauty
                            </Link>
                          </li>
                        </ul>
                        <ul className="end">
                          <li>
                            <Link to="/products">
                              <img src={icon3} alt="" />
                              Wines & Drinks
                            </Link>
                          </li>
                          <li>
                            <Link to="/products">
                              <img src={icon4} alt="" />
                              Fresh Seafood
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                          {/* <div className="more_categories">
                      <span className="icon"></span>{' '}
                      <span className="heading-sm-1">Show more...</span>
                    </div> */}
                        </div>
                      </div>
                    </OutsideClickHandler>

                    <div className="main-menu main-menu-padding-1 main-menu-lh-2 d-none d-lg-block font-heading w-100">
                      <nav>
                        <ul>
                          {/* <li className="hot-deals">
                        <img src={hotIcon} alt="hot deals" />
                        <Link to="/products">Hot Deals</Link>
                      </li> */}
                          <li>
                            <Link
                              // className="cat-listing"
                              // to={`/products/category/${cur.id}?name=${cur.name}`}
                              to={`/medicines`}
                            // style={({ isActive }) =>
                            //   isActive ? { color: '#E89F72' } : undefined
                            // }
                            >
                              Medicines
                            </Link>
                          </li>

                          {/* {initialCategory?.map((cur, i) => (
                        <>
                          {!cur?.children ? (
                            <li>
                              <NavLink
                                // className="cat-listing"
                                to={`/products/category/${cur.id}?name=${cur.title}`}
                                style={({ isActive }) =>
                                  isActive ? { color: '#E89F72' } : undefined
                                }
                              >
                                {cur.title}
                              </NavLink>
                            </li>
                          ) : (
                            cur?.children?.length > 0 && (
                              <li>
                                <NavLink
                                  to={`/products/category/${cur.id}?name=${cur.title}`}
                                >
                                  {cur.title}
                                  <i className="fi-rs-angle-down"></i>
                                </NavLink>

                                <ul key={i} className="sub-menu">
                                  {cur.children.map((c1sub) => (
                                    <li>
                                      <Link to="/shop-grid-right">
                                        {c1sub.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            )
                            cur?.children?.map((child1) => (
                              <>
                                {!child1?.children ? (
                                  <li>
                                    <Link
                                      to={`/products/category/${cur.id}?name=${cur.title}`}
                                    >
                                      {cur.title}
                                      <i className="fi-rs-angle-down"></i>
                                    </Link>
                                    {cur.children.map((c1sub, i) => (
                                      <ul key={i} className="sub-menu">
                                        <li>
                                          <Link to="/shop-grid-right">
                                            {c1sub.title}
                                          </Link>
                                        </li>
                                      </ul>
                                    ))}
                                  </li>
                                ) : null}
                              </>
                            ))
                          )}
                        </>
                      ))} */}

                          {/* <li>
                        <NavLink activeClassName="active" to="/about">
                          About
                        </NavLink>
                      </li> */}
                          {/* <li>
                        <NavLink activeClassName="active" to="/about">
                          Health Conditions
                        </NavLink>
                      </li>
                      <li>
                        <NavLink activeClassName="active" to="/about">
                          Maternity Solutions
                        </NavLink>
                      </li>
                      <li>
                        <Link to="/shop-grid-right">
                          Covid Core Solutions
                          <i className="fi-rs-angle-down"></i>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/shop-grid-right">
                              Shop Grid – Right Sidebar
                            </Link>
                          </li>
                          <li>
                            <Link to="/products">Shop Grid – Left Sidebar</Link>
                          </li>
                          <li>
                            <Link to="/shop-list-right">
                              Shop List – Right Sidebar
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-list-left">
                              Shop List – Left Sidebar
                            </Link>
                          </li>
                          <li>
                            <Link to="/shop-fullwidth">Shop - Wide</Link>
                          </li>
                          <li>
                            <Link to="/shop-filter">Shop - Filter</Link>
                          </li>
                        </ul>
                      </li>

                      <li>
                        <a href="#/">
                          Diabetes <i className="fi-rs-angle-down"></i>
                        </a>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/vendors">Vendors Grid</Link>
                          </li>
                          <li>
                            <Link to="/vendors-list">Vendors List</Link>
                          </li>
                          <li>
                            <Link to="/vendor-dashboard">Vendor Dashboard</Link>
                          </li>
                          <li>
                            <Link to="/vendor-guide">Vendor Guide</Link>
                          </li>
                        </ul>
                      </li> */}
                          {initialCategories?.map((cur) => {
                            return cur.nestedChildrenLvl === 0 ? (
                              <li key={cur.id}>
                                <Link
                                  // className="cat-listing"
                                  // to={`/products/category/${cur.id}?name=${cur.name}`}
                                  to={`/${cur.title?.replaceAll(/\s+/g, '-')}/filter?categoryId=${cur.id}&selected=category-${cur?.title}&categoryTreeIds=${cur.id}`}
                                  onClick={() =>
                                    addTofilter(cur.id, cur?.title)
                                  }
                                // style={({ isActive }) =>
                                //   isActive ? { color: '#E89F72' } : undefined
                                // }
                                >
                                  {cur.title}
                                </Link>
                              </li>
                            ) : cur.nestedChildrenLvl === 1 ? (
                              <li key={cur.id}>
                                <Link
                                  to={`/${cur.title?.replaceAll(/\s+/g, '-')}/filter?categoryId=${cur.id}&selected=category-${cur?.title}&categoryTreeIds=${cur.id}`}
                                  onClick={() =>
                                    addTofilter(cur.id, cur?.title)
                                  }
                                >
                                  {cur.title}
                                  <i className="fi-rs-angle-down"></i>
                                </Link>
                                <ul className="sub-menu">
                                  {cur.children?.map((child1) => (
                                    <li key={child1.id}>
                                      <Link
                                        to={`/${child1.title?.replaceAll(/\s+/g, '-')}/filter?categoryId=${cur.id}&selected=category-${cur?.title}&categoryTreeIds=${cur.id}`}
                                        onClick={() =>
                                          addTofilter(
                                            child1.id,
                                            child1?.title,
                                            cur?.title
                                          )
                                        }
                                      >
                                        {child1.title}
                                      </Link>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            ) : cur.nestedChildrenLvl === 2 ? (
                              <li className="position-static" key={cur.id}>
                                <Link
                                  to={`/${cur.title?.replaceAll(/\s+/g, '-')}/filter?categoryId=${cur.id}&selected=category-${cur?.title}&categoryTreeIds=${cur.id}`}
                                  onClick={() =>
                                    addTofilter(cur.id, cur?.title)
                                  }
                                >
                                  {cur.title}
                                  <i className="fi-rs-angle-down"></i>
                                </Link>

                                <ul className="mega-menu">
                                  {cur.children?.map((child1) => (
                                    <li
                                      key={child1.id}
                                      className="sub-mega-menu sub-mega-menu-width-22"
                                    >
                                      <Link
                                        className="menu-title"
                                        to={`/${child1.title?.replaceAll(/\s+/g, '-')}/filter?categoryId=${cur.id}&selected=category-${cur?.title}&categoryTreeIds=${cur.id}`}
                                        onClick={() =>
                                          addTofilter(child1.id, child1?.title)
                                        }
                                      >
                                        {child1.title}
                                      </Link>
                                      <ul>
                                        {child1.children?.map((child2) => (
                                          <li key={child2.id}>
                                            <Link
                                              to={`/${child2?.title?.replaceAll(/\s+/g, '-')}/filter?categoryId=${cur.id}&selected=category-${cur?.title}&categoryTreeIds=${cur.id}`}
                                              onClick={() =>
                                                addTofilter(
                                                  child2.id,
                                                  child2?.title
                                                )
                                              }
                                            >
                                              {child2.title}
                                            </Link>
                                          </li>
                                        ))}
                                      </ul>
                                    </li>
                                  ))}
                                  {/* <li className="sub-mega-menu sub-mega-menu-width-22">
                                <a className="menu-title" href="#/">
                                  Fruit & Vegetables
                                </a>
                                <ul>
                                  <li>
                                    <a href="#/">Meat & Poultry</a>
                                  </li>
                                  <li>
                                    <a href="#/">Fresh Vegetables</a>
                                  </li>
                                  <li>
                                    <a href="#/">Herbs & Seasonings</a>
                                  </li>
                                  <li>
                                    <a href="#/">Cuts & Sprouts</a>
                                  </li>
                                  <li>
                                    <a href="#/">Exotic Fruits & Veggies</a>
                                  </li>
                                  <li>
                                    <a href="#/">Packaged Produce</a>
                                  </li>
                                </ul>
                              </li> */}
                                </ul>
                              </li>
                            ) : null;
                          })}
                          {/* <li className="position-static">
                        <Link to="/#">
                          Sexual Wellness
                          <i className="fi-rs-angle-down"></i>
                        </Link>
                        <ul className="mega-menu">
                          <li className="sub-mega-menu sub-mega-menu-width-22">
                            <a className="menu-title" href="#/">
                              Fruit & Vegetables
                            </a>
                            <ul>
                              <li>
                                <a href="#/">Meat & Poultry</a>
                              </li>
                              <li>
                                <a href="#/">Fresh Vegetables</a>
                              </li>
                              <li>
                                <a href="#/">Herbs & Seasonings</a>
                              </li>
                              <li>
                                <a href="#/">Cuts & Sprouts</a>
                              </li>
                              <li>
                                <a href="#/">Exotic Fruits & Veggies</a>
                              </li>
                              <li>
                                <a href="#/">Packaged Produce</a>
                              </li>
                            </ul>
                          </li>
                          <li className="sub-mega-menu sub-mega-menu-width-22">
                            <a className="menu-title" href="#/">
                              Breakfast & Dairy
                            </a>
                            <ul>
                              <li>
                                <a href="#/">Milk & Flavoured Milk</a>
                              </li>
                              <li>
                                <a href="#/">Butter and Margarine</a>
                              </li>
                              <li>
                                <a href="#/">Eggs Substitutes</a>
                              </li>
                              <li>
                                <a href="#/">Marmalades</a>
                              </li>
                              <li>
                                <a href="#/">Sour Cream</a>
                              </li>
                              <li>
                                <a href="#/">Cheese</a>
                              </li>
                            </ul>
                          </li>
                          <li className="sub-mega-menu sub-mega-menu-width-22">
                            <a className="menu-title" href="#/">
                              Meat & Seafood
                            </a>
                            <ul>
                              <li>
                                <a href="#/">Breakfast Sausage</a>
                              </li>
                              <li>
                                <a href="#/">Dinner Sausage</a>
                              </li>
                              <li>
                                <a href="#/">Chicken</a>
                              </li>
                              <li>
                                <a href="#/">Sliced Deli Meat</a>
                              </li>
                              <li>
                                <a href="#/">Wild Caught Fillets</a>
                              </li>
                              <li>
                                <a href="#/">Crab and Shellfish</a>
                              </li>
                            </ul>
                          </li>
                          <li className="sub-mega-menu sub-mega-menu-width-34">
                            <div className="menu-banner-wrap">
                              <a href="#/">
                                <img
                                  src={require('assets/imgs/banner/banner-menu.png')}
                                  alt="Nest"
                                />
                              </a>
                              <div className="menu-banner-content">
                                <h4>Hot deals</h4>
                                <h3>
                                  Do not miss
                                  <br />
                                  Trending
                                </h3>
                                <div className="menu-banner-price">
                                  <span className="new-price text-success">
                                    Save to 50%
                                  </span>
                                </div>
                                <div className="menu-banner-btn">
                                  <a href="#/">Shop now</a>
                                </div>
                              </div>
                              <div className="menu-banner-discount">
                                <h3>
                                  <span>25%</span>
                                  off
                                </h3>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </li>
                      <li>
                        <Link to="/blog-category-grid">
                          Homeopathy
                          <i className="fi-rs-angle-down"></i>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/blog-category-grid">
                              Blog Category Grid
                            </Link>
                          </li>
                          <li>
                            <Link to="/blog-category-list">
                              Blog Category List
                            </Link>
                          </li>
                          <li>
                            <Link to="/blog-category-big">
                              Blog Category Big
                            </Link>
                          </li>
                          <li>
                            <Link to="/blog-category-fullwidth">
                              Blog Category Wide
                            </Link>
                          </li>
                          <li>
                            <Link to="/#">
                              Single Post
                              <i className="fi-rs-angle-right"></i>
                            </Link>
                            <ul className="level-menu level-menu-modify">
                              <li>
                                <Link to="/blog-post-left">Left Sidebar</Link>
                              </li>
                              <li>
                                <Link to="/blog-post-right">Right Sidebar</Link>
                              </li>
                              <li>
                                <Link to="/blog-post-fullwidth">
                                  No Sidebar
                                </Link>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li> */}
                          {/* <li>
                        <Link to="/#">
                          Pages
                          <i className="fi-rs-angle-down"></i>
                        </Link>
                        <ul className="sub-menu">
                          <li>
                            <Link to="/about">About Us</Link>
                          </li>
                          <li>
                            <Link to="/contact">Contact</Link>
                          </li>
                          <li>
                            <Link to="/account">My Account</Link>
                          </li>
                          <li>
                            <Link to="/login">login/register</Link>
                          </li>
                          <li>
                            <Link to="/page-purchase-guide">
                              Purchase Guide
                            </Link>
                          </li>
                          <li>
                            <Link to="/page-privacy-policy">
                              Privacy Policy
                            </Link>
                          </li>
                          <li>
                            <Link to="/page-terms">Terms of Service</Link>
                          </li>
                          <li>
                            <Link to="/page-404">404 Page</Link>
                          </li>
                        </ul>
                      </li> */}
                          {/* <li>
                        <Link to="/contact">Contact</Link>
                      </li> */}
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}
                {/* <div className="hotline d-none d-lg-flex">
                <img src={headphoneIcon} alt="hotline" />

                <p>
                  1900 - 888<span>24/7 Support Center</span>
                </p>
              </div> */}

                <div className="header-action-icon-2 d-block d-lg-none">
                  {/* <div className="burger-icon burger-icon-white">
                  <span className="burger-icon-top"></span>
                  <span className="burger-icon-mid"></span>
                  <span className="burger-icon-bottom"></span>
                </div> */}
                </div>

                <div className="header-action-right d-block d-lg-none">
                  <div className="header-action-2">
                    {/* <div className="header-action-icon-2">
                    <Link to="/wishlist">
                      <img alt="Evara" src={compareIcon} />
                      <span className="pro-count white">
                        {totalCompareItems}
                      </span>
                    </Link>
                  </div> */}

                    <div className="header-action-icon-2">
                      <Link to="/account#dashboard">
                        {/* <img className="svgInject" alt="Nest" src={userIcon} /> */}
                      </Link>
                      {authorized ? (
                        <>
                          <Link to="/account#dashboard">
                            <span className="lable ml-0">
                              {user?.firstName
                                ? `${user?.firstName.trim()}`
                                : 'Account'}
                            </span>
                          </Link>
                          <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                            <ul>
                              <li>
                                <Link to="/account#dashboard">
                                  <i className="fi fi-rs-user mr-10"></i>
                                  My Account
                                </Link>
                              </li>
                              {/* <li>
                              <Link to="/account">
                                <i className="fi fi-rs-location-alt mr-10"></i>
                                Order Tracking
                              </Link>
                            </li> */}
                              {/* <li>
                              <Link to="/account">
                                <i className="fi fi-rs-label mr-10"></i>
                                My Voucher
                              </Link>
                            </li> */}
                              {/* <li>
                                <Link to="/wishlist">
                                  <i className="fi fi-rs-heart mr-10"></i>
                                  My Wishlist
                                </Link>
                              </li> */}
                              {/* <li>
                              <Link to="/account">
                                <i className="fi fi-rs-settings-sliders mr-10"></i>
                                Setting
                              </Link>
                            </li> */}
                              <li>
                                {/* <Popconfirm
                                  title="Do you want to logout?"
                                  onConfirm={() => {
                                    dispatch(setLogout());
                                  }}
                                  className="logout-pop"
                                  okText="Yes"
                                  cancelText="No"
                                ></Popconfirm> */}
                                <Link to="">
                                  <i className="fi fi-rs-sign-out mr-10"></i>
                                  Sign out
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </>
                      ) : (
                        <Link to="/login">
                          <span className="lable ml-0">Login</span>
                        </Link>
                      )}
                    </div>

                    <div className="header-action-icon-2">
                      <Link to="/wishlist">
                        <img
                          alt="Evara"
                          style={{
                            width: '26px'
                          }}
                          src={""}
                          data-src={wishlists}
                        />
                        {totalWishlistItems > 0 && (
                          <span className="pro-count white">
                            {totalWishlistItems}
                          </span>
                        )}
                        {/* <span className="pro-count white">
                        {totalWishlistItems}
                      </span> */}
                      </Link>
                    </div>
                    <div className="header-action-icon-2">
                      <Link to="/cart" className="mini-cart-icon">
                        <img
                          alt="Evara"
                          src={""}
                          data-src={carts}
                          style={{
                            width: '33px',
                            marginBottom: '3px'
                            // height: '40px'
                          }}
                        />
                        {totalCartItems > 0 && (
                          <span
                            className="pro-count blue"
                            style={{ top: '-3px' }}
                          >
                            {totalCartItems}
                          </span>
                        )}
                      </Link>
                      {/* <div className="cart-dropdown-wrap cart-dropdown-hm2">
                      <ul>
                        <li>
                          <div className="shopping-cart-img">
                            <Link to="/shop-grid-right">
                              <img
                                alt="Evara"
                                src={require('assets/imgs/shop/thumbnail-3.jpg')}
                              />
                            </Link>
                          </div>
                          <div className="shopping-cart-title">
                            <h4>
                              <Link to="/shop-grid-right">
                                Plain Striola Shirts
                              </Link>
                            </h4>
                            <h3>
                              <span>1 × </span>
                              $800.00
                            </h3>
                          </div>
                          <div className="shopping-cart-delete">
                            <Link to="/#">
                              <i className="fi-rs-cross-small"></i>
                            </Link>
                          </div>
                        </li>
                        <li>
                          <div className="shopping-cart-img">
                            <Link to="/shop-grid-right">
                              <img
                                alt="Evara"
                                src={require('assets/imgs/shop/thumbnail-4.jpg')}
                              />
                            </Link>
                          </div>
                          <div className="shopping-cart-title">
                            <h4>
                              <Link to="/shop-grid-right">
                                Macbook Pro 2022
                              </Link>
                            </h4>
                            <h3>
                              <span>1 × </span>
                              $3500.00
                            </h3>
                          </div>
                          <div className="shopping-cart-delete">
                            <Link to="/#">
                              <i className="fi-rs-cross-small"></i>
                            </Link>
                          </div>
                        </li>
                      </ul>
                      <div className="shopping-cart-footer">
                        <div className="shopping-cart-total">
                          <h4>
                            Total
                            <span>$383.00</span>
                          </h4>
                        </div>
                        <div className="shopping-cart-button">
                          <Link to="/cart">View cart</Link>
                          <Link to="/shop-checkout">Checkout</Link>
                        </div>
                      </div>
                    </div> */}
                    </div>
                    <div className="header-action-icon-2">
                      <img
                        onClick={toggleClick}
                        width={'26'}
                        src={""}
                        data-src={MenuIcons}
                        alt="logo"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-search search-style-3 mobile-header-border mt-3 mx-2">
              <form
                onSubmit={formSubmit}
              >
                <input
                  type="text"
                  autoComplete="off"
                  name="searchQuery"
                  placeholder="Search for Medicines & Wellness Products"
                  onKeyDown={handleInput}
                  value={searchTerm}
                  onChange={onSearchChange}
                />
                <button type="submit" disabled={!searchTerm} >
                  <i className="fi-rs-search"></i>
                </button>
                <OutsideClickHandler
                  onOutsideClick={() => setIsSuggestionShown(false)}
                >
                  <SearchSuggestion
                    searchSuggestions={searchSuggestions}
                    onSelect={onSelect}
                    searchTerm={searchTerm}
                    setIsSuggestionShown={setIsSuggestionShown}
                    isSuggestionShown={isSuggestionShown}
                  />
                </OutsideClickHandler>
              </form>
            </div>
          </div>
        }
      </header>
    </>
  );
};

export default Header;
